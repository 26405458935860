<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <div class="row">
            <div class="col-md-12">
                <FilterRegional
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.region"/>
                <FilterArea
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.area"/>
                <FilterDistributor
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.distributor"
                    v-model:region="state.params.region"
                    v-model:area="state.params.area"/>
                <!-- <AInputSearch
                    class="mr-2"
                    v-model:value="state.params.q"
                    placeholder="Cari..."
                    style="width:300px"/> -->
                <AButton
                    class="mr-2"
                    type="primary"
                    title="cari"
                    @click="btnFetchData()"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </div>
        </div>

        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12"></div>
            <div class="col-lg-6 col-md-12 text-right">
                <DownloadExcel
                    :url="state.endpoint"
                    :params="state.params"
                    namefile="Laporan-Data-Gudang-Per-Area"
                    @errors="errorMessage"/>
            </div>
        </div>

        <div class="mt-4">
            <EChartCustom
                :series="state.gudang.series"
                :tooltip="state.gudang.tooltip"
                :legend="state.gudang.legend"
                :loading="state.gudang.loading"
                title="Data Gudang Per Area"
                high="600"
                def="original"/>
        </div>

    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import apiClient from '@/services/axios'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterArea from '@/components/filter/FilterArea'
import EChartCustom from '@/components/charts/EChartCustom'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import { pickBy, sumBy } from 'lodash'
import { FormatNumber } from '@/helpers/utils'

export default defineComponent({
    components: {
        FilterDistributor,
        FilterRegional,
        FilterArea,
        EChartCustom,
        DownloadExcel,
    },
    setup() {
        const errorMessage = ref()

        const state = reactive({
            endpoint: `/api/report/metabase-v2/gudang-area`,
            params: {
                q: '',
                region: [],
                distributor: [],
                area: [],
            },
            gudang: {
                loading: false,
                series: [],
                tooltip: {
                    trigger: 'item',
                    formatter: function (params) {
                        return `
                            Nama: ${params.data.name} <br />
                            Total: ${params.data.value} <br />
                            Persentase: ${params.percent}% <br />`
                    },
                },
                legend: {},
            },
        })

        const fetchDataGudangArea = () => {
            state.gudang.loading = true
            apiClient
                .get(state.endpoint, {
                    params: pickBy(state.params),
                })
                .then(({ data }) => {
                    const { items } = data

                    let total = sumBy(items, (item) => item.value)

                    state.gudang.series = [{
                        name: 'Data Gudang Per Area',
                        type: 'pie',
                        radius: ['40%', '80%'],
                        center: ['60%', '50%'],

                        // border bar pie
                        itemStyle: {
                            borderRadius: 10,
                            borderColor: '#fff',
                            borderWidth: 2,
                        },

                        // untuk label ditengah circle
                        markPoint: {
                            tooltip: { show: false },
                            label: {
                                show: true,
                                color: 'black',
                                fontSize: 20,
                            },
                            data: [{
                                value: `${FormatNumber(total)}\nTOTAL`,
                                symbol: 'circle',
                                itemStyle: { color: 'transparent' },
                                x: '60%',
                                y: '50%',
                            }],
                        },

                        data: items,
                    }]

                    state.gudang.legend = {
                        type: 'scroll',
                        orient: 'vertical',
                        top: '10%',
                        bottom: '10%',
                        left: '15%',
                        formatter: (name) => {
                            var value = items.filter(row => row.name === name)[0].value
                            return `${name}  ${((value / total) * 100).toFixed(4)}%`;
                        },
                        tooltip: {
                            show: true,
                            formatter: function ({ name }) {
                                let item = items.filter(row => row.name === name)[0]
                                return `
                                    Nama: ${item.name} <br />
                                    Total: ${item.value} <br />
                                    Persentase: ${((item.value / total) * 100).toFixed(4)}% <br />`
                            },
                        },
                    }
                    
                })
                .catch(error => console.error(error))
                .finally(() => {
                    state.gudang.loading = false
                })
        }

        const btnFetchData = () => {
            fetchDataGudangArea()
        }

        // handle vue
        onMounted(() => {
            btnFetchData()
        })

        return {
            btnFetchData,
            state,
            errorMessage,
        }
    },
})
</script>

<style lang="scss" scoped>
.card {
    height: 100%;
    .card-body {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
}
</style>